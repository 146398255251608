import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route
} from 'react-router-dom';
//import { message } from 'antd';
import axios from 'axios';
import config from './config'

import Login from './page/Login'
import Home from './page/Home'

axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  // 用户未登录
  if (response.data.code === "U299") {
    if (window.location.hash !== "#/login") {
      // message.error("登录状态失效", 2)
      window.location.href = '/#/login';
    }
    return response;
  } else {
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    if (window.location.hash === "#/") {
      window.location.href = '/#/login';
    }
    let phpsessid = localStorage.getItem('phpsessid')
    this.checkLogin(phpsessid);
  }

  // 登录状态检查
  checkLogin = (phpsessid) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/home/checklogin',
      data: {
        phpsessid: phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          if (window.location.hash === "#/login") {
            window.location.href = '/#/home/admanage';
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <Router>
        <div>
          <Route path='/login' component={Login} />
          <Route path='/home' component={Home} />
        </div>
      </Router>
    );
  }
}

export default App;
