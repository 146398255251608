import React, { Component } from 'react';
import { Input, DatePicker, Button, Select, Divider, Table, Modal, Form, Upload, Icon, message, Popconfirm } from 'antd';
import axios from 'axios'
import moment from 'moment'
import config from '../config';

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 添加对话框可见性
      modalVisible: false,
      // 对话框标题
      modalTitle: '',
      // 主图地址
      imageUrl: '',
      // 图片校验提示文字
      imageUrlVerifyTip: '',
      // 表格数据
      tableData: [],
      // 单个数据详情
      singleDetail: {},
      // 当前正在编辑数据id
      currentEditId: null,
      // 分页
      pagination: {},
      // 名称查询
      search: '',
      // 状态查询
      status: undefined,
      // 时间查询
      searchTime: undefined,
      // 开始时间查询
      start_time: '',
      // 关闭时间查询
      end_time: '',
      // 当前页码
      page: 1,
      // 每页条数
      per_page: 6,
      phpsessid: null
    };
  }

  componentDidMount() {
    let phpsessid = localStorage.getItem('phpsessid');
    this.setState({
      phpsessid: phpsessid
    })
    this.getList(phpsessid);
  }

  // 获取列表数据
  getList = (phpsessid) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/miniadver/index',
      data: {
        search: this.state.search,
        status: Number(this.state.status),
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        page: this.state.page,
        per_page: this.state.per_page,
        phpsessid: phpsessid,
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          let data = res.data.data;
          const pagination = { ...this.state.pagination };
          pagination.total = data.total;
          pagination.pageSize = this.state.per_page;
          this.setState({
            tableData: data.data,
            pagination: pagination
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 搜索名改变
  searchChange = (e) => {
    this.setState({
      search: e.target.value
    })
  }

  // 状态选择
  statusOptionChange = (e) => {
    console.log(e)
    this.setState({
      status: e
    })
  }

  searchTimeChange = (e) => {
    console.log(e)
    this.setState({
      searchTime: e,
      start_time: moment(e[0]).format('YYYY-MM-DD'),
      end_time: moment(e[1]).format('YYYY-MM-DD')
    })
  }

  // 查询操作
  onSearch = (e) => {
    this.getList(this.state.phpsessid)
  }

  // 重置查询条件
  resetSearch = () => {
    this.setState({
      search: '',
      status: undefined,
      searchTime: undefined,
      start_time: '',
      end_time: ''
    })
    setTimeout(() => {
      this.getList(this.state.phpsessid)
    }, 100)
  }

  // 新建广告-显示对话框
  showModel = () => {
    this.setState({
      modalVisible: true,
      modalTitle: '新建广告',
      imageUrl: '',
      singleDetail: {}
    })
  }

  // 对话框确认
  handleModelOk = () => {
    // 校验图片上传
    if (this.state.imageUrl === '') {
      this.setState({
        imageUrlVerifyTip: '请上传广告主图'
      })
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // 表单数据上传
        // console.log('Received values of form: ', values);
        let url = config.baseUrl + '/miniadver/addminiadver';
        let data = {
          name: values.name,
          type: Number(values.type),
          url: 'https://' + values.link,
          imgpath: this.state.imageUrl,
          starttime: moment(values.starttime).format('YYYY-MM-DD HH:mm'),
          endtime: moment(values.endtime).format('YYYY-MM-DD HH:mm'),
          phpsessid: this.state.phpsessid
        }
        let tip = '添加成功';
        if (this.state.modalTitle === "编辑广告") {
          url = config.baseUrl + '/miniadver/editminiadver';
          data.id = this.state.currentEditId;
          tip = "修改成功";
        }
        axios({
          method: 'post',
          url: url,
          data: data
        })
          .then(res => {
            if (res.data.code === "U100") {
              message.success(tip, 2);
              this.setState({
                modalVisible: false,
              });
              this.getList(this.state.phpsessid);
            } else {
              message.error(res.data.msg, 2);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  }

  // 对话框取消
  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      imageUrl: '',
      imageUrlVerifyTip: ''
    });
  }

  // 上传文件之前的钩子
  beforeImgUpload = (file) => {
    let formData = new FormData();
    formData.append("file", file)
    formData.append("phpsessid", this.state.phpsessid)
    axios({
      method: 'post',
      url: config.baseUrl + '/home/uploadimg',
      data: formData
    })
      .then(res => {
        if (res.data.code === "U100") {
          this.setState({
            imageUrl: res.data.imgpath,
            imageUrlVerifyTip: ''
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
    return false;
  }

  // 分页切换
  handleTableChange = (pagination) => {
    this.setState({
      page: pagination.current
    })
    setTimeout(() => {
      this.getList(this.state.phpsessid)
    }, 100)
  }

  // 发布操作
  publishOperation = (id) => {
    this.changeStatus(id, 1, '发布成功')
  }

  // 结束操作
  endOperation = (id) => {
    this.changeStatus(id, 2, '结束成功')
  }

  // 更改广告状态
  changeStatus = (id, status, msg) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/miniadver/changestatus',
      data: {
        id: id,
        status: status,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          this.getList(this.state.phpsessid)
          message.success(msg, 2)
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 删除操作
  deleteOperation = (id) => {
    console.log('id :', id);
    axios({
      method: 'post',
      url: config.baseUrl + '/miniadver/delminiadver',
      data: {
        id: id,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          this.getList(this.state.phpsessid)
          message.success('删除成功', 2)
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 编辑操作
  editOperation = (id) => {
    this.setState({
      modalVisible: true,
      modalTitle: '编辑广告',
      currentEditId: id
    })
    this.getDetail(id)
  }

  // 获取单个广告详情
  getDetail = (id) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/miniadver/getminiadver',
      data: {
        id: id,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          let data = res.data.data;
          data.type = String(data.type);
          data.starttime = moment(data.starttime, 'YYYY-MM-DD HH:mm');
          data.endtime = moment(data.endtime, 'YYYY-MM-DD HH:mm');
          data.urlpath = data.urlpath.slice(8);
          this.setState({
            singleDetail: data,
            imageUrl: data.imgpath
          })
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  columns = [{
    title: '主图',
    align: 'center',
    dataIndex: 'imgpath',
    render: (text) => <img src={text} alt="" style={{ height: '50px' }} />
  }, {
    title: '广告名称',
    align: 'center',
    dataIndex: 'name'
  }, {
    title: '广告链接',
    align: 'center',
    dataIndex: 'urlpath'
  }, {
    title: '开始时间',
    align: 'center',
    dataIndex: 'starttime'
  }, {
    title: '结束时间',
    align: 'center',
    dataIndex: 'endtime'
  }, {
    title: '广告位',
    align: 'center',
    dataIndex: 'type',
    render: (text) => (<span>广告{text}</span>)
  }, {
    title: '状态',
    align: 'center',
    dataIndex: 'status',
    render: (text) => (<span><span style={{ color: "#F92440" }}>{text === 0 ? '未发布' : ''}</span> <span style={{ color: "#FFB53E" }}>{text === 1 ? '已发布' : ''}</span> <span style={{ color: "#1EBFAF" }}>{text === 2 ? '已结束' : ''}</span></span>)
  }, {
    title: '操作',
    align: 'center',
    key: 'action',
    render: (text, record) => {
      let publish = null;
      let editDelete = null;
      let end = null;
      // 未发布
      if (record.status === 0) {
        publish = (
          <span>
            <Popconfirm title="确认要发布该广告？" onConfirm={() => { this.publishOperation(record.id) }} okText="确认" cancelText="取消">
              <span style={{ color: '#1890ff', cursor: 'pointer' }}>发布</span>
            </Popconfirm>
            <Divider type="vertical" />
          </span>
        )
      }
      // 未发布 跟 已结束
      if (record.status === 0 || record.status === 2) {
        editDelete = (
          <span>
            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.editOperation(record.id) }}> 编辑</span>
            <Divider type="vertical" />
            <Popconfirm title="确认要删除该广告？" onConfirm={() => { this.deleteOperation(record.id) }} okText="确认" cancelText="取消">
              <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
            </Popconfirm>
          </span>
        )
      }
      // 已发布
      if (record.status === 1) {
        end = (
          <Popconfirm title="确认要结束该广告？" onConfirm={() => { this.endOperation(record.id) }} okText="确认" cancelText="取消">
            <span style={{ color: '#1890ff', cursor: 'pointer' }}>结束</span>
          </Popconfirm>
        )
      }
      return (
        <span>
          {publish}
          {editDelete}
          {end}
        </span >
      )
    },
  }];

  render() {
    const { getFieldDecorator } = this.props.form;
    // 单条数据详情
    const singleDetail = this.state.singleDetail;
    // 主图地址
    const imageUrl = this.state.imageUrl;
    // 图片上传按钮
    const uploadButton = (
      <div>
        <Icon type='plus' />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    return (
      <div>
        <div className="ad-manage details">
          <div className="search">
            <span className="item">
              搜索内容：<Input placeholder="请输入广告名称" onChange={this.searchChange} value={this.state.search} style={{ width: '200px' }} />
            </span>
            <span className="item">
              状态：
            <Select
                showSearch
                placeholder="请选择"
                style={{ width: '150px' }}
                onChange={this.statusOptionChange}
                value={this.state.status}
              >
                <Option value="0">未发布</Option>
                <Option value="1">已发布</Option>
                <Option value="2">已结束</Option>
              </Select>
            </span>
            <span className="item">
              日期：<RangePicker onChange={this.searchTimeChange} value={this.state.searchTime} />
            </span>
            <span className="item query">
              <Button onClick={this.onSearch} type="primary">查询</Button>
              <Button onClick={this.resetSearch}>重置</Button>
            </span>
          </div>
          <div className="operation">
            <div className="item">
              <Button type="primary" icon="plus" onClick={this.showModel}>新建</Button>
            </div>
          </div>
          <div className="list">
            <Table
              columns={this.columns}
              rowKey={record => record.id}
              dataSource={this.state.tableData}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
        <Modal
          title={this.state.modalTitle}
          visible={this.state.modalVisible}
          onOk={this.handleModelOk}
          onCancel={this.handleModalCancel}
          centered
          destroyOnClose
          maskClosable={false}
          wrapClassName="add-modal"
          width="700px"
        >
          <Form>
            <div className="left">
              <FormItem label="广告名称">
                {getFieldDecorator('name', {
                  initialValue: singleDetail.name,
                  rules: [{
                    required: true,
                    message: '请输入广告名称',
                  }],
                })(
                  <Input placeholder="请输入广告名称" />
                )}
              </FormItem>
              <FormItem label="开始时间">
                {getFieldDecorator('starttime', {
                  initialValue: singleDetail.starttime,
                  rules: [{
                    required: true,
                    message: '请选择开始时间',
                  }],
                })(
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="请选择开始时间"
                    style={{ width: '300px' }}
                  />
                )}
              </FormItem>
              <FormItem label={<span><span style={{ color: '#f5222d' }}>* </span>广告主图</span>}
                validateStatus="error"
                help={this.state.imageUrlVerifyTip}>
                <div className="dropbox">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeImgUpload}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '86px', height: '86px' }} /> : uploadButton}
                  </Upload>
                </div>
              </FormItem>
            </div>
            <div className="right">
              <FormItem label="广告位">
                {getFieldDecorator('type', {
                  initialValue: singleDetail.type,
                  rules: [{
                    required: true,
                    message: '请选择广告位',
                  }],
                })(
                  <Select placeholder="请选择广告位" >
                    <Option value="1">广告位1</Option>
                    <Option value="2">广告位2</Option>
                    <Option value="3">广告位3</Option>
                    <Option value="4">广告位4</Option>
                    <Option value="5">广告位5</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem label="结束时间">
                {getFieldDecorator('endtime', {
                  initialValue: singleDetail.endtime,
                  rules: [{
                    required: true,
                    message: '请选择结束时间',
                  }],
                })(
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="请选择结束时间"
                    style={{ width: '300px' }}
                  />
                )}
              </FormItem>
              <FormItem label="广告链接">
                {getFieldDecorator('link', {
                  initialValue: singleDetail.urlpath,
                  rules: [{
                    required: true,
                    message: '请输入广告链接',
                  }],
                })(
                  <Input addonBefore="https://" placeholder="请输入广告链接" />
                )}
              </FormItem>
            </div>
          </Form>
        </Modal >
      </div >
    );
  }
}

export default Form.create()(Demo);