import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import axios from 'axios';
import config from '../config'
import './style.css';

import bg from '../source/login-bg.jpg'
import logo from '../source/login-logo.png'
import pdShow from '../source/login-pd-show.png'
import pdHide from '../source/login-pd-hide.png'

const FormItem = Form.Item;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdStatus: 0
    };
  }

  // 登录表单提交
  handleSubmit = (e) => {
    let history = this.props.history;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.post(config.baseUrl + '/home/adminlogin', {
          username: values.userName,
          password: values.password
        })
          .then(function (res) {
            if (res.data.code === "U100") {
              localStorage.setItem('phpsessid', res.data.phpsessid)
              history.push("/home/admanage");
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    });
  }

  // 密码显示隐藏
  pdShowHide = () => {
    if (this.state.pdStatus === 0) {
      this.setState({
        pdStatus: 1
      })
    } else {
      this.setState({
        pdStatus: 0
      })
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <img src={bg} alt="" style={{ width: '100vw', height: '100vh' }} />
        <div className="login">
          <img className="logo" src={logo} alt="" style={{ width: '150px' }} />
          <Form onSubmit={this.handleSubmit} >
            <FormItem label="用户名">
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: '用户名不能为空！' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} className="login-form-input" placeholder="请输入用户名" />
              )}
            </FormItem>
            <FormItem label="登录密码">
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '登录密码不能为空！' }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} className="login-form-input" type={this.state.pdStatus === 0 ? "password" : ''} placeholder="请输入登录密码" />
              )}
              <div className="pd-show-hide" onClick={this.pdShowHide}><img src={this.state.pdStatus === 0 ? pdHide : pdShow} alt="" /></div>
            </FormItem>
            <FormItem>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(
                <Checkbox>自动登录</Checkbox>
              )}
              <Button type="primary" htmlType="submit" className="login-form-input login-form-submit">
                登录
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create({})(Demo);
// export default withRouter(Form.create({})(Demo));