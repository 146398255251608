import React, { Component } from 'react';
import { Input, Button, Select, Table } from 'antd';
import config from '../config';

const Option = Select.Option;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 当前类型
      currentType: 5,
      // 类型列表
      typeOptions: [{
        id: 5,
        name: '请选择'
      }, {
        id: 0,
        name: '下载注册'
      }, {
        id: 1,
        name: '关注公众号'
      }, {
        id: 2,
        name: '授权小程序'
      }, {
        id: 3,
        name: '其他'
      }],
      // 表格数据
      tableData: [{
        id: 1,
        name: '好看小视频下载注册',
        imgpath: 'https://i0.hippopx.com/photos/320/918/427/sky-clouds-sunlight-dark-thumb.jpg',
        count: 830,
        coin: 12,
        type: 0,
        waitaudit: 60,
        passed: 16,
        passfault: 6
      }],
      // 分页
      pagination: {},
      // 加载
      loading: false,
      // 当前页码
      currentPage: 1,
      // 每页条数
      pageSize: 10,
    };
  }

  statusOptionChange = (e) => {
    console.log(e)
    this.setState({
      currentStatus: e
    })
  }

  typeOptionChange = (e) => {
    console.log(e)
    this.setState({
      currentType: e
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current
    })
    this.fetch({
      rowNum: this.state.pageSize,
      page: pagination.current,
      uid: config.user.uid,
      name: this.state.nameOnChangeQueryValue,
      remark: this.state.remarkOnChangeQueryValue
    });
  }

  // fetch = (params = {}) => {
  //   // console.log('params:', params);
  //   this.setState({ loading: true });
  //   axios.get(config.baseUrl + 'file/list', {
  //     params: {
  //       ...params
  //     }
  //   })
  //     .then(res => {
  //       let data = res.data.data;
  //       // console.log(data.rows.length);
  //       const pagination = { ...this.state.pagination };
  //       pagination.total = data.total;
  //       this.setState({
  //         loading: false,
  //         data: data.rows,
  //         pagination,
  //       });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }

  columns = [{
    title: '主图',
    align: 'center',
    dataIndex: 'imgpath',
    render: (text) => <img src={text} alt="" style={{ height: '50px' }} />
  }, {
    title: '任务名称',
    align: 'center',
    dataIndex: 'name'
  }, {
    title: '任务次数',
    align: 'center',
    dataIndex: 'count',
    render: (text) => (<span>{text}次</span>)
  }, {
    title: '可获印币',
    align: 'center',
    dataIndex: 'coin'
  }, {
    title: '任务类型',
    align: 'center',
    dataIndex: 'type',
    render: (text) => (<span>{text === 0 ? '下载注册' : ''}{text === 1 ? '关注公众号' : ''}{text === 2 ? '授权小程序' : ''}{text === 3 ? '其他' : ''}</span>)
  }, {
    title: '待审核数量',
    align: 'center',
    dataIndex: 'waitaudit'
  }, {
    title: '已通过数量',
    align: 'center',
    dataIndex: 'passed'
  }, {
    title: '未通过数量',
    align: 'center',
    dataIndex: 'passfault'
  }, {
    title: '操作',
    align: 'center',
    key: 'action',
    render: (text, record) => {
      return (
        <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.auditOperation(record.id) }}>审核</span>
      )
    },
  }];

  render() {
    // 类型列表
    let typeOptions = this.state.typeOptions.map(d => <Option key={d.id} value={d.id} title={d.name}>{d.name}</Option>);

    return (
      <div className="miniapp-manage details">
        <div className="search">
          <span className="item">
            搜索内容：<Input placeholder="请输入任务名称" style={{ width: '250px' }} />
          </span>
          <span className="item">
            任务类型：
            <Select
              showSearch
              placeholder="请选择"
              style={{ width: '250px' }}
              value={this.state.currentType}
              onChange={this.typeOptionChange}
            >
              {typeOptions}
            </Select>
          </span>
          <span className="item query">
            <Button type="primary">查询</Button>
            <Button>重置</Button>
          </span>
        </div>
        <div className="list">
          <Table
            columns={this.columns}
            rowKey={record => record.id}
            dataSource={this.state.tableData}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Demo;