import React, { Component } from 'react';
import { Input, Button, Select, Divider, Table } from 'antd';
import config from '../config';

const Option = Select.Option;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 当前状态
      currentStatus: 4,
      // 状态列表
      statusOptions: [{
        id: 4,
        name: '请选择'
      }, {
        id: 0,
        name: '未发布'
      }, {
        id: 1,
        name: '已发布'
      }, {
        id: 2,
        name: '已结束'
      }],
      // 当前类型
      currentType: 5,
      // 类型列表
      typeOptions: [{
        id: 5,
        name: '请选择'
      }, {
        id: 0,
        name: '下载注册'
      }, {
        id: 1,
        name: '关注公众号'
      }, {
        id: 2,
        name: '授权小程序'
      }, {
        id: 3,
        name: '其他'
      }],
      // 表格数据
      tableData: [{
        id: 1,
        name: '好看小视频下载注册',
        imgpath: 'https://i0.hippopx.com/photos/320/918/427/sky-clouds-sunlight-dark-thumb.jpg',
        count: 830,
        type: 0,
        updatetime: '2018/06/22  10:32',
        coin: 12,
        audittime: 16,
        status: 0
      }],
      // 分页
      pagination: {},
      // 加载
      loading: false,
      // 当前页码
      currentPage: 1,
      // 每页条数
      pageSize: 10,
    };
  }

  // 
  statusOptionChange = (e) => {
    console.log(e)
    this.setState({
      currentStatus: e
    })
  }

  typeOptionChange = (e) => {
    console.log(e)
    this.setState({
      currentType: e
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current
    })
    this.fetch({
      rowNum: this.state.pageSize,
      page: pagination.current,
      uid: config.user.uid,
      name: this.state.nameOnChangeQueryValue,
      remark: this.state.remarkOnChangeQueryValue
    });
  }


  columns = [{
    title: '主图',
    align: 'center',
    dataIndex: 'imgpath',
    render: (text) => <img src={text} alt="" style={{ height: '50px' }} />
  }, {
    title: '任务名称',
    align: 'center',
    dataIndex: 'name'
  }, {
    title: '任务次数',
    align: 'center',
    dataIndex: 'count',
    render: (text) => (<span>{text}次</span>)
  }, {
    title: '任务类型',
    align: 'center',
    dataIndex: 'type',
    render: (text) => (<span>{text === 0 ? '下载注册' : ''}{text === 1 ? '关注公众号' : ''}{text === 2 ? '授权小程序' : ''}{text === 3 ? '其他' : ''}</span>)
  }, {
    title: '更新时间',
    align: 'center',
    dataIndex: 'updatetime'
  }, {
    title: '可获印币',
    align: 'center',
    dataIndex: 'coin'
  }, {
    title: '审核时间',
    align: 'center',
    dataIndex: 'audittime',
    render: (text) => (<span>{text}天</span>)
  }, {
    title: '任务状态',
    align: 'center',
    dataIndex: 'status',
    render: (text) => (<span><span style={{ color: "#F92440" }}>{text === 0 ? '未发布' : ''}</span> <span style={{ color: "#FFB53E" }}>{text === 1 ? '已发布' : ''}</span> <span style={{ color: "#1EBFAF" }}>{text === 2 ? '已结束' : ''}</span></span>)
  }, {
    title: '操作',
    align: 'center',
    key: 'action',
    render: (text, record) => {
      let publish = null;
      let editDelete = null;
      let end = null;
      // 未发布
      if (record.status === 0) {
        publish = (
          <span>
            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.publishOperation(record.id) }}>发布</span>
            <Divider type="vertical" />
          </span>
        )
      }
      // 未发布 跟 已结束
      if (record.status === 0 || record.status === 2) {
        editDelete = (
          <span>
            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.editOperation(record.id) }}> 编辑</span>
            <Divider type="vertical" />
            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.deleteOperation(record.id) }}>删除</span>
          </span>
        )
      }
      // 已发布
      if (record.status === 1) {
        end = <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.endOperation(record.id) }}>结束</span>
      }
      return (
        <span>
          {publish}
          {editDelete}
          {end}
        </span >
      )
    },
  }];

  render() {
    // 状态列表
    let statusOptions = this.state.statusOptions.map(d => <Option key={d.id} value={d.id} title={d.name}>{d.name}</Option>);
    // 类型列表
    let typeOptions = this.state.typeOptions.map(d => <Option key={d.id} value={d.id} title={d.name}>{d.name}</Option>);

    return (
      <div className="miniapp-manage details">
        <div className="search">
          <span className="item">
            搜索内容：<Input placeholder="请输入任务名称" style={{ width: '200px' }} />
          </span>
          <span className="item">
            状态：
            <Select
              showSearch
              placeholder="请选择"
              style={{ width: '150px' }}
              value={this.state.currentStatus}
              onChange={this.statusOptionChange}
            >
              {statusOptions}
            </Select>
          </span>
          <span className="item">
            任务类型：
            <Select
              showSearch
              placeholder="请选择"
              style={{ width: '150px' }}
              value={this.state.currentType}
              onChange={this.typeOptionChange}
            >
              {typeOptions}
            </Select>
          </span>
          <span className="item query">
            <Button type="primary">查询</Button>
            <Button>重置</Button>
          </span>
        </div>
        <div className="operation">
          <div className="item">
            <Button type="primary" icon="plus">新建</Button>
          </div>
        </div>
        <div className="list">
          <Table
            columns={this.columns}
            rowKey={record => record.id}
            dataSource={this.state.tableData}
            pagination={this.state.pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Demo;