let config = {};

if (process.env.NODE_ENV === 'development') {
  config.dev = true;
} else {
  config.dev = false;
}

if (config.dev) {
  config.baseUrl = 'https://miniadmin.sinzk.com'
} else {
  config.baseUrl = 'https://miniadmin.sinzk.com'
}

config.user = {
  username: '',
}

export default config;