import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route
} from 'react-router-dom'
import { Menu, Icon } from 'antd';

import AdManage from './AdManage';
import MiniAppManage from './MiniAppManage';
import TaskList from './TaskList';
import TaskAudit from './TaskAudit';

import logo from '../source/login-logo.png'

const SubMenu = Menu.SubMenu;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenuKey: "2"
    };
  }

  componentWillMount() {
    let history = this.props.history;
    let phpsessid = localStorage.getItem('phpsessid');
    if (!phpsessid) {
      history.push("/login");
    }
    if (history.location.pathname === "/home/admanage") {
      this.setState({
        currentMenuKey: "1"
      })
    }
    if (history.location.pathname === "/home/miniappmanage") {
      this.setState({
        currentMenuKey: "2"
      })
    }
    if (history.location.pathname === "/home/tasklist") {
      this.setState({
        currentMenuKey: "3"
      })
    }
    if (history.location.pathname === "/home/taskaudit") {
      this.setState({
        currentMenuKey: "4"
      })
    }
  }


  handleClick = (e) => {
    let history = this.props.history;
    if (e.key === "1" && this.state.currentMenuKey !== "1") {
      this.setState({
        currentMenuKey: "1"
      })
      history.push('/home/admanage')
    }
    if (e.key === "2" && this.state.currentMenuKey !== "2") {
      this.setState({
        currentMenuKey: "2"
      })
      history.push('/home/miniappmanage')
    }
    if (e.key === "3" && this.state.currentMenuKey !== "3") {
      this.setState({
        currentMenuKey: "3"
      })
      history.push('/home/tasklist')
    }
    if (e.key === "4" && this.state.currentMenuKey !== "4") {
      this.setState({
        currentMenuKey: "4"
      })
      history.push('/home/taskaudit')
    }
  }

  render() {
    return (
      <Router>
        <div className="home">
          <div className="left">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="title">小程序管理后台</div>
            <Menu
              onClick={this.handleClick}
              style={{ width: 256 }}
              defaultSelectedKeys={[this.state.currentMenuKey]}
              defaultOpenKeys={['sub4']}
              mode="inline"
              theme="dark"
            >
              <Menu.Item key="1" ><span><Icon type="tags" theme="filled" /><span>广告管理</span></span>
              </Menu.Item>
              <Menu.Item key="2" ><span><Icon type="appstore" theme="filled" /><span>小程序管理</span></span>
              </Menu.Item>
              <SubMenu key="sub4" title={<span><Icon type="setting" /><span>任务管理</span></span>}>
                <Menu.Item key="3">任务列表</Menu.Item>
                <Menu.Item key="4">任务审核</Menu.Item>
              </SubMenu>
            </Menu>
          </div>
          <div className="content">
            <div className="user-bar">
              <div className="avatar">
                <img src="https://avatars0.githubusercontent.com/u/8327775?s=460&v=4" alt="" />
                <span>niices</span>
              </div>
            </div>
            <Route
              path="/home/admanage"
              component={AdManage}
            />
            <Route
              path="/home/miniappmanage"
              component={MiniAppManage}
            />
            <Route
              path="/home/tasklist"
              component={TaskList}
            />
            <Route
              path="/home/taskaudit"
              component={TaskAudit}
            />
          </div>
        </div>
      </Router>
    );
  }
}

export default Demo;