import React, { Component } from 'react';
import { Input, DatePicker, Button, Select, Divider, Table, Modal, Form, message, Popconfirm } from 'antd';
import axios from 'axios'
import moment from 'moment'
import config from '../config';

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 添加对话框可见性
      modalVisible: false,
      // 对话框标题
      modalTitle: '',
      // 主图地址
      // imageUrl: '',
      // 图片校验提示文字
      // imageUrlVerifyTip: '',
      // 表格数据
      tableData: [],
      // 单个数据详情
      singleDetail: {},
      // 当前正在编辑数据id
      currentEditId: null,
      // 分页
      pagination: {},
      // 名称查询
      search: '',
      // 状态查询
      status: undefined,
      // 时间查询
      searchTime: undefined,
      // 开始时间查询
      start_time: '',
      // 关闭时间查询
      end_time: '',
      // 当前页码
      page: 1,
      // 每页条数
      per_page: 10,
      phpsessid: null
    };
  }

  componentDidMount() {
    let phpsessid = localStorage.getItem('phpsessid');
    this.setState({
      phpsessid: phpsessid
    })
    this.getList(phpsessid);
  }

  // 获取列表数据
  getList = (phpsessid) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/mini/index',
      data: {
        search: this.state.search,
        status: Number(this.state.status),
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        page: this.state.page,
        per_page: this.state.per_page,
        phpsessid: phpsessid,
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          let data = res.data.data;
          let tableData = data.data;
          const pagination = { ...this.state.pagination };
          pagination.total = data.total;
          pagination.pageSize = this.state.per_page;
          this.setState({
            tableData: tableData,
            pagination: pagination
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 搜索名改变
  searchChange = (e) => {
    this.setState({
      search: e.target.value
    })
  }

  // 状态选择
  statusOptionChange = (e) => {
    console.log(e)
    this.setState({
      status: e
    })
  }

  searchTimeChange = (e) => {
    console.log(e)
    this.setState({
      searchTime: e,
      start_time: moment(e[0]).format('YYYY-MM-DD'),
      end_time: moment(e[1]).format('YYYY-MM-DD')
    })
  }

  // 查询操作
  onSearch = (e) => {
    this.getList(this.state.phpsessid)
  }

  // 重置查询条件
  resetSearch = () => {
    this.setState({
      search: '',
      status: undefined,
      searchTime: undefined,
      start_time: '',
      end_time: ''
    })
    setTimeout(() => {
      this.getList(this.state.phpsessid)
    }, 100)
  }

  // 新建小程序-显示对话框
  showModel = () => {
    this.setState({
      modalVisible: true,
      modalTitle: '新建小程序',
      // imageUrl: '',
      singleDetail: {}
    })
  }

  // 对话框确认
  handleModelOk = () => {
    // 校验图片上传
    // if (this.state.imageUrl === '') {
    //   this.setState({
    //     imageUrlVerifyTip: '请上传小程序主图'
    //   })
    // }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // 表单数据上传
        // console.log('Received values of form: ', values);
        let url = config.baseUrl + '/mini/addmini';
        let data = {
          name: values.name,
          appid: values.appid,
          url: values.url,
          param: values.param,
          starttime: moment(values.starttime).format('YYYY-MM-DD HH:mm'),
          endtime: moment(values.endtime).format('YYYY-MM-DD HH:mm'),
          discount: Number(values.discount),
          phpsessid: this.state.phpsessid
        }
        console.log(data)
        let tip = '添加成功';
        if (this.state.modalTitle === "编辑小程序") {
          url = config.baseUrl + '/mini/editmini';
          data.id = this.state.currentEditId;
          tip = "修改成功";
        }
        axios({
          method: 'post',
          url: url,
          data: data
        })
          .then(res => {
            if (res.data.code === "U100") {
              message.success(tip, 2);
              this.setState({
                modalVisible: false,
              });
              this.getList(this.state.phpsessid);
            } else {
              message.error(res.data.msg, 2);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  }

  // 对话框取消
  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      // imageUrl: '',
      // imageUrlVerifyTip: ''
    });
  }

  // 上传文件之前的钩子
  // beforeImgUpload = (file) => {
  //   let formData = new FormData();
  //   formData.append("file", file)
  //   formData.append("phpsessid", this.state.phpsessid)
  //   axios({
  //     method: 'post',
  //     url: config.baseUrl + '/home/uploadimg',
  //     data: formData
  //   })
  //     .then(res => {
  //       if (res.data.code === "U100") {
  //         this.setState({
  //           imageUrl: res.data.imgpath,
  //           imageUrlVerifyTip: ''
  //         })
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  //   return false;
  // }

  // 分页切换
  handleTableChange = (pagination) => {
    this.setState({
      page: pagination.current
    })
    setTimeout(() => {
      this.getList(this.state.phpsessid)
    }, 100)
  }

  // 发布操作
  publishOperation = (id) => {
    this.changeStatus(id, 1, '发布成功')
  }

  // 结束操作
  endOperation = (id) => {
    this.changeStatus(id, 2, '结束成功')
  }

  // 更改小程序状态
  changeStatus = (id, status, msg) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/mini/changestatus',
      data: {
        id: id,
        status: status,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          this.getList(this.state.phpsessid)
          message.success(msg, 2)
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 删除操作
  deleteOperation = (id) => {
    console.log('id :', id);
    axios({
      method: 'post',
      url: config.baseUrl + '/mini/delmini',
      data: {
        id: id,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          this.getList(this.state.phpsessid)
          message.success('删除成功', 2)
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // 编辑操作
  editOperation = (id) => {
    this.setState({
      modalVisible: true,
      modalTitle: '编辑小程序',
      currentEditId: id
    })
    this.getDetail(id)
  }

  // 获取单个小程序详情
  getDetail = (id) => {
    axios({
      method: 'post',
      url: config.baseUrl + '/mini/getmini',
      data: {
        id: id,
        phpsessid: this.state.phpsessid
      }
    })
      .then(res => {
        if (res.data.code === "U100") {
          let data = res.data.data;
          data.url = data.urlpath;
          data.starttime = moment(data.starttime, 'YYYY-MM-DD HH:mm');
          data.endtime = moment(data.endtime, 'YYYY-MM-DD HH:mm');
          this.setState({
            singleDetail: data,
            // imageUrl: data.imgpath
          })
        } else {
          message.error(res.data.msg, 2)
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  columns = [
    // {
    //   title: '主图',
    //   align: 'center',
    //   dataIndex: 'imgpath',
    //   render: (text) => <img src={text} alt="" style={{ height: '50px' }} />
    // },
    {
      title: '小程序名称',
      align: 'center',
      dataIndex: 'name',
      width: 120
    }, {
      title: 'appId',
      align: 'center',
      dataIndex: 'appid',
      width: 160
    }, {
      title: '页面路径',
      align: 'center',
      dataIndex: 'urlpath',
      width: 180
    }, {
      title: '页面参数',
      align: 'center',
      dataIndex: 'param',
      width: 180
    }, {
      title: '抵扣金额（元）',
      align: 'center',
      dataIndex: 'discount',
      width: 100
    }, {
      title: '状态',
      align: 'center',
      dataIndex: 'status',
      width: 100,
      render: (text) => (<span><span style={{ color: "#F92440" }}>{text === 0 ? '未发布' : ''}</span> <span style={{ color: "#FFB53E" }}>{text === 1 ? '已发布' : ''}</span> <span style={{ color: "#1EBFAF" }}>{text === 2 ? '已结束' : ''}</span></span>)
    }, {
      title: '开始时间',
      align: 'center',
      dataIndex: 'starttime',
      width: 180
    }, {
      title: '结束时间',
      align: 'center',
      dataIndex: 'endtime',
      width: 180
    }, {
      title: '操作',
      align: 'center',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => {
        let publish = null;
        let editDelete = null;
        let end = null;
        // 未发布
        if (record.status === 0) {
          publish = (
            <span>
              <Popconfirm title="确认要发布该小程序？" onConfirm={() => { this.publishOperation(record.id) }} okText="确认" cancelText="取消">
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>发布</span>
              </Popconfirm>
              <Divider type="vertical" />
            </span>
          )
        }
        // 未发布 跟 已结束
        if (record.status === 0 || record.status === 2) {
          editDelete = (
            <span>
              <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => { this.editOperation(record.id) }}>编辑</span>
              {/* <Divider type="vertical" />
              <Popconfirm title="确认要复制该小程序？" onConfirm={() => { this.copyOperation(record.id) }} okText="确认" cancelText="取消">
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>复制</span>
              </Popconfirm> */}
              <Divider type="vertical" />
              <Popconfirm title="确认要删除该小程序？" onConfirm={() => { this.deleteOperation(record.id) }} okText="确认" cancelText="取消">
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            </span>
          )
        }
        // 已发布
        if (record.status === 1) {
          end = (
            <span>
              {/* <Popconfirm title="确认要复制该小程序？" onConfirm={() => { this.copyOperation(record.id) }} okText="确认" cancelText="取消">
                <span style={{ color: '#1890ff', cursor: 'pointer' }}>复制</span>
              </Popconfirm>
              <Divider type="vertical" /> */}
              <Popconfirm title="确认要结束该小程序？" onConfirm={() => { this.endOperation(record.id) }} okText="确认" cancelText="取消">
                <span style={{ color: '#1890ff', cursor: 'pointer' }} >结束</span>
              </Popconfirm>
            </span>
          )
        }
        return (
          <span>
            {publish}
            {editDelete}
            {end}
          </span >
        )
      },
    }];
  render() {
    const { getFieldDecorator } = this.props.form;
    // 单条数据详情
    const singleDetail = this.state.singleDetail;
    // 主图地址
    // const imageUrl = this.state.imageUrl;
    // 图片上传按钮
    // const uploadButton = (
    //   <div>
    //     <Icon type='plus' />
    //     <div className="ant-upload-text">上传</div>
    //   </div>
    // );
    return (
      <div>
        <div className="ad-manage details">
          <div className="search">
            <span className="item">
              搜索内容：<Input placeholder="请输入小程序名称" onChange={this.searchChange} value={this.state.search} style={{ width: '200px' }} />
            </span>
            <span className="item">
              状态：
            <Select
                showSearch
                placeholder="请选择"
                style={{ width: '150px' }}
                onChange={this.statusOptionChange}
                value={this.state.status}
              >
                <Option value="0">未发布</Option>
                <Option value="1">已发布</Option>
                <Option value="2">已结束</Option>
              </Select>
            </span>
            <span className="item">
              日期：<RangePicker onChange={this.searchTimeChange} value={this.state.searchTime} />
            </span>
            <span className="item query">
              <Button onClick={this.onSearch} type="primary">查询</Button>
              <Button onClick={this.resetSearch}>重置</Button>
            </span>
          </div>
          <div className="operation">
            <div className="item">
              <Button type="primary" icon="plus" onClick={this.showModel}>新建</Button>
            </div>
          </div>
          <div className="list">
            <Table
              columns={this.columns}
              rowKey={record => record.id}
              dataSource={this.state.tableData}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              scroll={{ x: 1400 }}
            />
          </div>
        </div>
        <Modal
          title={this.state.modalTitle}
          visible={this.state.modalVisible}
          onOk={this.handleModelOk}
          onCancel={this.handleModalCancel}
          centered
          destroyOnClose
          maskClosable={false}
          wrapClassName="add-modal"
          width="700px"
        >
          <Form>
            <div className="left">
              <FormItem label="小程序名称">
                {getFieldDecorator('name', {
                  initialValue: singleDetail.name,
                  rules: [{
                    required: true,
                    message: '请输入小程序名称',
                  }],
                })(
                  <Input placeholder="请输入小程序名称" />
                )}
              </FormItem>
              <FormItem label="小程序页面路径">
                {getFieldDecorator('url', {
                  initialValue: singleDetail.url,
                  rules: [{
                    required: true,
                    message: '请输入小程序页面路径',
                  }],
                })(
                  <Input placeholder="e.g /pages/index/index" />
                )}
              </FormItem>
              <FormItem label="开始时间">
                {getFieldDecorator('starttime', {
                  initialValue: singleDetail.starttime,
                  rules: [{
                    required: true,
                    message: '请选择开始时间',
                  }],
                })(
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="请选择开始时间"
                    style={{ width: '300px' }}
                  />
                )}
              </FormItem>
              <FormItem label="折扣金额（元）">
                {getFieldDecorator('discount', {
                  initialValue: singleDetail.discount,
                  rules: [{
                    required: true,
                    message: '请输入折扣金额',
                  }],
                })(
                  <Input placeholder="请输入折扣金额" />
                )}
              </FormItem>
              {/* <FormItem label={<span><span style={{ color: '#f5222d' }}>* </span>小程序主图</span>}
                validateStatus="error"
                help={this.state.imageUrlVerifyTip}>
                <div className="dropbox">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeImgUpload}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '86px', height: '86px' }} /> : uploadButton}
                  </Upload>
                </div>
              </FormItem> */}
            </div>
            <div className="right">
              <FormItem label="appId">
                {getFieldDecorator('appid', {
                  initialValue: singleDetail.appid,
                  rules: [{
                    required: true,
                    message: '请输入小程序appId',
                  }],
                })(
                  <Input placeholder="请输入小程序appId" />
                )}
              </FormItem>
              <FormItem label="小程序页面参数">
                {getFieldDecorator('param', {
                  initialValue: singleDetail.param,
                  rules: [{
                    required: true,
                    message: '请输入小程序页面参数',
                  }],
                })(
                  <Input placeholder="e.g {from: 'sinzk'}" />
                )}
              </FormItem>
              <FormItem label="结束时间">
                {getFieldDecorator('endtime', {
                  initialValue: singleDetail.endtime,
                  rules: [{
                    required: true,
                    message: '请选择结束时间',
                  }],
                })(
                  <DatePicker
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="请选择结束时间"
                    style={{ width: '300px' }}
                  />
                )}
              </FormItem>
            </div>
          </Form>
        </Modal >
      </div >
    );
  }
}

export default Form.create()(Demo);